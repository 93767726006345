import AppSource from '../data/app-source';
import { paymentRequestDummy } from '../utils/dummy';
import formatRupiah from '../utils/format-rupiah';
import { buttonFilled, inputWIconLeading, loadPage } from '../views/templates/components';

const CreateRequestHandler = {
  init({
    createRequestContainer,
    containerRequestForm,
    containerRequestMessage,
    containerLoad,
  }) {
    const _userNim = localStorage.getItem('USER_NIM');
    const _userToken = localStorage.getItem('USER_TOKEN');
    if (!_userToken) location.assign('#/login');
    this._insertComponent(containerRequestForm);
    this._reqOnSubmit({
      _userToken, _userNim, createRequestContainer, containerRequestMessage, containerLoad,
    });
    this._nominalFormat(createRequestContainer);
  },

  _insertComponent(containerRequestForm) {
    containerRequestForm.innerHTML += inputWIconLeading('request-nominal', 'Input Nominal', 'nominal', '', '', 'number');
    containerRequestForm.innerHTML += buttonFilled('request-btn', 'Create', 'mt-5');
    const requestNominal = containerRequestForm.querySelector('#request-nominal');
    requestNominal.lastElementChild.innerHTML += '<p id="show-rupiah" class="absolute top-3 left-5 text-[#3572EF]">0</p>';
  },

  _nominalFormat(createRequestContainer) {
    const requestNominalInput = createRequestContainer.querySelector('#request-nominal input');
    const showRupiah = createRequestContainer.querySelector('#show-rupiah');
    requestNominalInput.classList.add('text-white');
    requestNominalInput.addEventListener('keyup', () => {
      showRupiah.textContent = formatRupiah(requestNominalInput.value);
    });
  },

  _reqOnSubmit({
    _userToken, _userNim, createRequestContainer, containerRequestMessage, containerLoad,
  }) {
    const requestBtn = createRequestContainer.querySelector('#request-btn');
    requestBtn.addEventListener('click', async () => {
      const requestNominalInput = createRequestContainer.querySelector('#request-nominal input').value;
      const payload = {
        token: _userToken,
        nim: _userNim,
        nominal: Number(requestNominalInput),
      };
      containerLoad.innerHTML += loadPage;

      const { error, message } = await AppSource.createPaymentRequest(payload);
      // ------------------------- dummy -------------------------
      // const { error, message } = paymentRequestDummy;

      containerLoad.lastElementChild.remove();
      if (message === 401) this._error(createRequestContainer);
      else containerRequestMessage.textContent = message;
      if (!error) {
        requestBtn.setAttribute('disabled', 'disabled');
        requestBtn.classList.remove('bg-[#050C9C]');
        requestBtn.classList.add('bg-[#DCDCDC]');
        setInterval(() => location.assign('/'), 3000);
      }
    });
  },

  _error(createRequestContainer) {
    createRequestContainer.innerHTML = '<error-page></error-page>';
    const backHomeBtn = createRequestContainer.querySelector('#back-home-btn');
    backHomeBtn.textContent = 'Login';
    backHomeBtn.addEventListener('click', () => location.assign('#/login'));
  },
};

export default CreateRequestHandler;
